import axios from "axios";
import React from "react";
import { useToast } from "@chakra-ui/react";
import { GetCookie, SetCookie } from "../provider/common";

export async function AddCart(
  it_id,
  total_qty,
  it_maker,
  origin_pn,
  packaging,
  is_stock
) {
  const token = await GetCookie("token");
  //장바구니 쿠키값이 있는지 체크하기
  const data = {
    it_id: Number(it_id),
    quantity: Number(total_qty),
    cart_type: 0,
    it_maker: it_maker,
    origin_pn: origin_pn,
    packaging: packaging,
    is_stock: is_stock,
  };
  const res = await axios.post(process.env.PANDA_API_URL + "/api/cart", data, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ` + token,
    },
  });
  if (res.data.status === 201) {
    var result = window.confirm(
      "장바구니에 저장되었습니다. 장바구니로 이동할까요?"
    );
    if (result) {
      window.location.href = "/cart/view";
    }
  } else {
    alert(
      "장바구니 저장중 오류가 발생했습니다. 잠시후 다시 시도 부탁드립니다. \n지속적으로 문제시 고객센터로 문의 부탁드립니다."
    );
  }
}

export async function EmptyCart() {
  alert("구매 가능한 수량이 없습니다.");
}

export function cutBelowChange(val) {
  const change = process.env.PRICE_CHANGE; // 잔돈 기준, 100원
  return Math.floor(val / change) * change;
}

export function vat(val) {
  //부가세 계산 후 반올림
  return Math.round(val / 10);
}

// 장바구니 선택한 상품만 견적서 엑셀 다운로드
export function cart_select_excel_down(ct_ids, mb_id) {
  let values = [];
  values = ct_ids.toString();
  cart_excel_down("cart", "", values, mb_id);
}

// 장바구니 견적서 출력 ( 체크박스 서택한 아이템들만 보여주기 )
export function cart_show_estimate(ct_ids, to, mb_id) {
  if (!to) to = "";
  let values = [];
  values = ct_ids.toString();
  show_estimate("cart", "", values, to, mb_id);
}

export function order_show_estimate(id, to, mb_id) {
  show_estimate("order", id, "", undefined, mb_id);
}

//견적서 다운로드
export function cart_excel_down(type, id, ct_ids, mb_id) {
  if (ct_ids == undefined) ct_ids = "";
  location.href =
    process.env.OLD_PANDA_URL +
    "/cart/excel_down?type=" +
    type +
    "&id=" +
    id +
    "&cart_ids=" +
    ct_ids +
    "&mb_id=" +
    mb_id;
}

// 견적서 출력
export function show_estimate(type, id, ct_ids, to, mb_id) {
  if (ct_ids == undefined) ct_ids = "";
  window.open(
    process.env.OLD_PANDA_URL +
      "/user/estimate?to=" +
      to +
      "&type=" +
      type +
      "&id=" +
      id +
      "&cart_ids=" +
      ct_ids +
      "&mb_id=" +
      mb_id,
    "견적서",
    "width=850, height=600, menubar=no, status=no, toolbar=no"
  );
}

// 거래내역서 보기 팝업
export function show_invoice(id) {
  window.open(
    process.env.OLD_PANDA_URL + "/user/invoice/" + id,
    "거래명세표",
    "width=850, height=600, menubar=no, status=no, toolbar=no"
  );
}

// 거래내역서 보기 팝업
export function show_invoice_vat(id) {
  window.open(
    process.env.OLD_PANDA_URL + "/user/invoice_vat/" + id,
    "거래명세표",
    "width=850, height=600, menubar=no, status=no, toolbar=no"
  );
}

export async function getCouponList() {
  const token = await GetCookie("token");
  //장바구니 쿠키값이 있는지 체크하기
  const res = await axios.get(
    process.env.PANDA_API_URL + "/api/coupon?type=user_available",
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ` + token,
      },
    }
  );
  if (res.data.status === 200) {
    return res.data.list;
  }
}

export function CaculateDiscount(price, amount) {
  let discount = 0;
  //할인율로 계산
  discount = amount * (price / 100);
  lowPrice(discount);

  return Math.ceil(discount);
}

//할인금액이 100원보다 작으면 무조건 100원 할인설정
export function lowPrice(discount) {
  if (discount == 0) {
    discount = 0;
  } else if (discount < 100) {
    discount = 100;
  }
  return discount;
}

export function sortPrice(data) {
  const ordered = data.sort(function (a, b) {
    return parseFloat(a.Quantity) - parseFloat(b.Quantity);
  });
}

export function checkPayType(method) {
  switch (method) {
    case "신용카드":
    case "비인증":
      return "card";
    case "계좌이체":
    case "가상계좌":
    case "에스크로":
    case "선발행":
      return "vbank";
    case "삼성페이":
      return "onlyssp";
    case "Lpay":
      return "onlylpay";
    case "SSG pay":
      return "onlyssgcard";
    case "카카오페이":
      return "onlykakaopay";
  }
}

export function checkPayMID(method) {
  switch (method) {
    case "비인증":
      return { mid: process.env.INI_MID2, mkey: process.env.INI_MKEY2 };
    default:
      return { mid: process.env.INI_MID1, mkey: process.env.INI_MKEY1 };
  }
}

// 메인 아이템 장바구니에 담기
export async function goCart(productData) {
  const token = await GetCookie("token");

  const addCartURL =
    process.env.PANDA_API_URL + `/api/mallshop/getProduct/${productData.it_id}`;
  const addCartRes = await axios.get(addCartURL, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ` + token,
    },
  });

  let body = {
    it_id: Number(addCartRes.data.data.it_id),
    quantity: Number(addCartRes.data.data.it_buy_min_qty),
    cart_type: 0,
    it_maker: addCartRes.data.data.it_maker,
    origin_pn: addCartRes.data.data.it_name,
    packaging: addCartRes.data.data.it_basic ?? "",
    is_stock: "US",
    unit_price: addCartRes.data.data.it_price,
    is_mall: "Y",
    it_soldout: productData.it_soldout,
  };

  const URL = process.env.PANDA_API_URL + "/api/cart";
  const res = await axios.post(URL, body, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ` + token,
    },
  });

  if (res.data.status === 201) {
    var result = window.confirm(
      "장바구니에 저장되었습니다. 장바구니로 이동할까요?"
    );
    // 장바구니에 이동
    if (result) {
      window.location.href = "/cart/view";
    }
    // 최대 구매수량을 초과했을 때 발생
  } else {
    alert("장바구니 저장중 오류가 발생했습니다. \n사유 : " + res.data.msg);
  }
  return;
}
